// Config values
$platform-name: "Mental Health Matters";
$assets-path: "../assets/" ;

//Fonts
@font-face {
    font-family: "VAG Rounded";
    src: url($assets-path + "VAG_Rounded_Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "VAG Rounded";
    src: url($assets-path + "VAG_Rounded_Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

// Colours
$brand-primary: #DD0394;
$brand-secondary: #221973;
$brand-tertiary: #808285;

$hover-primary: #9E1965;
$hover-secondary: #1A1741;

$bg-dark: #221973;
$bg-light: #3C3691;
$bg-color: #FFFFFF;
$bg-grey: #F4F3F8;

$font-colour: #0C0C0C;
$body-colour: #221973;
$inverse-colour: #808285;

// Links
$link-colour: #DD0394;

// Greyscale
$black: #000;
$white: #fff;

// Logo
$logo-width: 182px;
$logo-height: 65px;
$logo-mobile-width: 112px;
$logo-mobile-height: 40px;

// Typography
$font-family-base: "VAG Rounded", sans-serif;

// Font sizes
$font-size-base: 16px;
$font-size-large: 20px;
$font-size-small: 14px;

$font-size-h1: 49px;
$font-size-h2: 39px;
$font-size-h3: 31px;
$font-size-h4: 25px;
$font-size-h5: 20px;
$font-size-h6: 16px;

$font-mobile-h1: 29px;
$font-mobile-h2: 26px;
$font-mobile-h3: 23px;
$font-mobile-h4: 20px;
$font-mobile-h5: 18px;

$font-display-1: 99px;
$font-display-2: 82px;
$font-display-3: 69px;
$font-display-4: 57px;

// Font weights
$font-weight-normal: 400;
$font-weight-bold: 700;
$line-height-base: 1.625;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1199px
);

// Headings
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.4;
$headings-colour: #221973;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Layout
$container-max-width: 1600px;
$container-grid-size: 85%;
$site-bleed: 0px;

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: $white;
$btn-padding-x: 31.5px;
$btn-padding-y: 17px;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-line-height: 1.375;

// Search
$header-search-margin-right: 15px;

// Search - input
$header-search-input-max-width: 220px;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 21.5px 3.5px;
$header-search-input-placeholder-colour: $white;
$header-search-input-border-radius: 0;
$header-search-input-border-colour: $white;
$header-search-input-border-width: 0px 0px 1px 0px; // t-r-b-l
$header-search-input-background-colour: transparent;
$header-search-input-colour: $white;
$header-search-input-focus-background-colour: transparent;
$header-search-input-focus-colour: $white;

// Search - button
$header-search-button-width: 12px;
$header-search-button-height: 15px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 12px;
$header-search-button-icon-colour: $white;
$header-search-button-border-radius: 0;

// Menu admin
$menu-admin-contents-max-width: $container-max-width;
$menu-admin-background-colour: $brand-primary;
$menu-admin-font-size: $font-size-base;
$menu-admin-link-colour: $white;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: 20px;
$header-content-padding-bottom: 20px;

// Navigation
$nav-background-colour: transparent;
$nav-breakpoint: map-get($breakpoints, xl);

// Top level items
$nav-top-level-item-padding: 0;
$nav-top-level-item-colour: $body-colour;
$nav-top-level-item-font-size: $font-size-large;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-colour: $brand-primary;
$nav-top-level-item-hover-background-colour: transparent;

// Submenus
$nav-submenu-width: 270px;
$nav-submenu-background-colour: $bg-grey;
$nav-submenu-item-padding: 15px 20px;
$nav-submenu-item-colour: $body-colour;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-normal;

// Burger button
$burger-btn-background-colour: transparent;
$burger-btn-text-colour: $brand-secondary;

// Nav normal
$nav-normal-mobile-background-colour: $bg-dark;
$nav-normal-mobile-top-level-item-colour: $white;
$nav-normal-mobile-submenu-item-colour: $white;
$nav-normal-mobile-submenu-background-colour: rgba($white, 0.08);

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-details-style: adjacent; // overlay, adjacent or below
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 100%;
$carousel-details-padding: 0;
$carousel-details-border-radius: 0;
$carousel-heading-colour: $white;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-colour: $white;
$carousel-summary-font-size: $font-size-large;
$carousel-read-more-background-colour: $brand-primary;

// Carousel controls (left-right buttons)
$carousel-controls-button-size: 50px;
$carousel-controls-button-gap: 20px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-border-radius: 0;
$carousel-controls-button-box-shadow: transparent;
$carousel-controls-position-y: bottom; // top, center, bottom
$carousel-controls-position-x: left; // split, left or right
$carousel-controls-icon-colour: transparent;
$carousel-controls-next-icon: "\f054"; // TODO: These are temporarily suspended
$carousel-controls-prev-icon: "\f053"; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: transparent;
$carousel-mobile-heading-colour: $white;
$carousel-mobile-summary-colour: $white;

// Home intro
$home-intro-max-width: 745px;
$home-intro-background-colour: transparent;
$home-intro-text-align: center;
$home-intro-padding-y: 0;
$home-intro-margin-top: 100px;
$home-intro-margin-bottom: 60px;
$home-intro-font-size: $font-size-h3;
$home-intro-mobile-font-size: $font-mobile-h3;

// Home features
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;

// Home feeds
$home-feeds-max-width: 100%;
$home-feeds-background-colour: transparent;
$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 0;

// Feeds title
$feeds-title-colour: $headings-colour;
$feeds-title-text-align: center; // left, center, right
$feeds-title-margin-bottom: 0;

// Feed item
$feed-item-read-more-enabled: true;

// Card
$card-border: none;
$card-image-background-colour: transparent;
$card-details-padding: 30px;
$card-details-background-colour: $bg-grey;
$card-heading-font-size: $font-size-h4;
$card-heading-margin-bottom: 10px;
$card-heading-colour: $bg-dark;
$card-summary-enabled: true;
$card-summary-colour: $bg-dark;

// Card hover state
$card-hover-heading-colour: $white;
$card-hover-summary-colour: $white;
$card-hover-image-opacity: 1;
$card-hover-details-background-colour: $bg-dark;

// Footer
$footer-padding-y: 0;
$footer-background-colour: $bg-dark;
$footer-colour: $white;
$footer-link-decoration: none;
$footer-link-hover-decoration: none;

// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// Footer specific social icons
$social-icons-background-colour: $brand-primary; // A colour, transparent, or 'brand'
$social-icons-colour: $white; // A colour, or 'brand'
$social-icons-hover-colour: $white; // A colour, or 'brand'
$social-icons-width: 32px;
$social-icons-height: 32px;
$social-icons-font-size: $font-size-base;
$social-icons-border-radius: 50%;
$social-icons-gap: 10px;

// Newsletter
$newsletter-background-colour: $bg-light;
$newsletter-colour: $white;
$newsletter-heading-text-align: center; // left, center, right
$newsletter-heading-margin-bottom: 30px;
$newsletter-button-height: 50px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $brand-primary;
$newsletter-button-colour: $font-colour;
$newsletter-button-border-colour: $brand-primary;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: 60px;
$newsletter-hero-contents-max-width: 100%;

// Quick giving panel
$quick-giving-background-colour: transparent;
$quick-giving-padding: 0;
$quick-giving-donation-amount-figure-font-size: $font-size-h5;
$quick-giving-donation-amount-padding: 17.5px 19.5px;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: $font-colour;
$quick-giving-donation-amount-selected-background-colour: $brand-primary;
$quick-giving-donation-amount-selected-figure-colour: $white;

// Impact stats
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 100px;
$impact-stats-padding-y: 0;
$impact-stats-background-colour: transparent;
$impact-stats-border-radius: 0;
$impact-stats-text-align: center; // left, center, right

// Heading
$impact-stats-heading-colour: $brand-secondary;
$impact-stats-heading-margin-bottom: 0;

// Individual stat
$impact-stat-min-width: auto; // Use this to control how many you get in a row
$impact-stat-margin: 0;

// Figure
$impact-stats-figure-colour: $brand-primary;
$impact-stats-figure-font-size: $font-size-h2;

// Summary
$impact-stats-summary-colour: $brand-secondary;
$impact-stats-summary-font-size: $font-size-base;
$impact-stats-summary-font-weight: $font-weight-bold;

// Slick
$slick-enabled: true;

// Sidebar
$sidebar-layout: below; // adjacent, below or above - where do you want the sidebar to be positioned on posts?
$sidebar-adjacent-gap: 60px; // distance between sidebar and post content
$sidebar-border-radius: 0;

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h2;
$associated-item-image-enabled: true;
$associated-item-heading-font-size: $font-size-h5;
$associated-item-heading-colour: $headings-colour;
$associated-item-summary-enabled: true;
$associated-item-border: false;

// Blockquote
$blockquote-background-colour: $brand-primary;
$blockquote-border-radius: 0;
$blockquote-padding: 60px 30px 40px;
$blockquote-text-align: left; // left, center, right
$blockquote-font-size: $font-size-large; // Em unit will cascade to children
$blockquote-colour: $white;
